<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Detail Verifikator</strong>
        </h5>
        <a-spin v-if="isLoading" />
        <a-form :form="form"  @submit="handleSubmit" v-if="!isLoading">
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Nama Lengkap">
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Nama Lengkap Verifikator"
              v-decorator="['fullname', {rules: [{ required: true, message: 'Nama verifikator harus diisi!' }]}]"
            />
          </a-form-item>
          <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Alamat E-mail">
            <a-input
              :disabled="submitting"
              placeholder="Alamat E-mail"
              v-decorator="['email', {rules: [{ required: true, message: 'E-mail harus diisi!' }]}]"
            />
          </a-form-item>
          <!-- <a-form-item :label-col="labelCol" :wrapper-col="wrapperCol" label="Password">
            <a-button class="btn btn-primary px-5 m-1" :disabled="submitting" @click="(e)=>{e.preventDefault(); setModalVisible2(true)}">Reset Password</a-button>
          </a-form-item> -->
          <button type="submit" class="btn btn-success px-5" :disabled="submitting">Simpan Data</button>
          <button class="btn btn-danger px-5 m-1" :disabled="submitting" @click="(e)=>{e.preventDefault(); setModalVisible(true)}">Hapus Data</button>
          <button class="btn btn-info px-5 m-1" @click="() => { $router.push(`/verifikator/details/${$route.params.id}/change-password`) }">Change Password</button>
          <button class="btn btn-primary px-5 m-1" :disabled="submitting" @click="(e)=>{e.preventDefault(); setModalVisible2(true)}">Reset Password</button>
          <button class="btn btn-secondary px-5" :disabled="submitting" @click="handleCancel">Kembali</button>
        </a-form>
      </div>
    </div>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible"
      @ok="() => handleDelete()"
      @cancel="() => setModalVisible(false)"
    >
      Apakah Anda yakin akan menghapus data verifikator {{form.getFieldValue("fullname")}}?
    </a-modal>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible2"
      @ok="() => handleResetPassword(false)"
      @cancel="() => setModalVisible2(false)"
    >
      Apakah Anda yakin akan mereset password verifikator {{form.getFieldValue("fullname")}}?
      <br/> email akan dikirimkan ke {{form.getFieldValue("email")}}
    </a-modal>
  </div>
</template>
<script>

import router from '@/router'
import { getById, editById, deleteById } from '@/services/axios/api/verifikator'
import { getAll } from '@/services/axios/api/city'
import { resetPassword } from '@/services/axios/api/auth'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 4 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      isLoading: true,
      submitting: false,
      modalVisible: false,
      modalVisible2: false,
    }
  },
  created() {
    this.getVerifikatorById()
    this.getCityList()
  },
  methods: {
    async getVerifikatorById() {
      // init form field
      this.form.getFieldDecorator('fullname', { initialValue: '' })
      this.form.getFieldDecorator('email', { initialValue: '' })
      this.form.getFieldDecorator('role', { initialValue: 'verificator' })
      // get data
      const res = await getById(this.$route.params.id)

      // set loading false
      this.isLoading = false

      if (res) {
        // set form data
        this.form.setFieldsValue({ fullname: res.fullname })
        this.form.setFieldsValue({ email: res.email })
      }
    },
    async getCityList() {
      const res = await getAll()
      this.cityList = res
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await editById(this.$route.params.id, values)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil disimpan',
                duration: 5,
              })
              router.push('/verifikator')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    async handleDelete() {
      const res = await deleteById(this.$route.params.id)
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Data berhasil dihapus',
          duration: 5,
        })
        router.push('/verifikator')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Data gagal dihapus',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/verifikator')
    },
    async handleResetPassword() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await resetPassword(values, 'verificator')
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Password berhasil direset, silakan cek email terdaftar',
                duration: 5,
              })
            } else {
              notification.warning({
                message: 'Gagal',
                description: 'Password gagal direset',
                duration: 5,
              })
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
          this.modalVisible2 = false
        }
      })
    },
    setModalVisible(modalVisible) {
      this.modalVisible = modalVisible
    },
    setModalVisible2(modalVisible2) {
      this.modalVisible2 = modalVisible2
    },
  },
}
</script>
